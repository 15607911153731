

// Colors for BFT
$bft-lime-green: #ccd553;
$bft-lime-green-light: #d1d964;
$bft-taupe-dark: #494748;
$bft-taupe: #5c5a5b;
$bft-brown: #635b57;
$bft-brown-light: #736b68;
$bft-tan: #8a8777;
$bft-sage-light: #728275;
$bft-sage: #637465;
$bft-off-white: #f3f1e6;
$bft-white: #ffffff;
$bft-red: #b32f36;


$theme-colors: (
    "primary": $bft-brown-light,
    "secondary": $bft-tan,
    "danger": $bft-red,
    "success": $bft-lime-green,
    "warning": $bft-lime-green-light
);

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
);
/*
$basic-color: #736B68;

$background-color: #F5F3EA;
$basic-text-color1: #F4F3E9;
$basic-text-hover-color1: rgba($basic-text-color1, .7);
$basic-text-color2: #726B68;
$basic-text-hover-color2: rgba($basic-text-color2, .7);

$background-color3: #ccd553; // lime green
$basic-text-color4: #728175;
$basic-text-color5: #637465;

$navigation-dark-background-color: #494748;
*/
// Aignment across elements
$left-padding-alignment: 40px;
$right-padding-alignment: 24px;
$left-padding-alignment-navbar: 24px;

$font-family-base: "Open Sans", sanserif;
$font-family-alternate: "Bitter", serif;
$headings-font-family: "Bitter", serif;
//$font-family-alternate: "Adelle", serif;
//$headings-font-family: "Adelle", serif;
$headings-color: $bft-off-white;

$navbar-light-color:                $bft-brown-light;
$navbar-light-hover-color:          rgba($bft-brown-light, .7);
$navbar-light-active-color:         rgba($bft-brown-light, .9);
$navbar-light-disabled-color:       rgba($bft-brown-light, .3);
$navbar-light-toggler-icon-bg:      url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'><path stroke='#{$navbar-light-color}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>") !default;
$navbar-light-toggler-border-color: rgba($bft-brown-light, .1);

$navbar-dark-color:                 rgba($bft-off-white, 0.9) ;
$navbar-dark-hover-color:           rgba($bft-off-white, .75);
$navbar-dark-active-color:          $bft-off-white;
$navbar-dark-disabled-color:        rgba($bft-off-white, .25);
$navbar-dark-toggler-icon-bg:       url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'><path stroke='#{$navbar-dark-color}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>") !default;
$navbar-dark-toggler-border-color:  rgba($bft-off-white, .1);

$navbar-brand-font-size: 1rem;
$font-size-base: 1rem;
$input-placeholder-color: rgba($bft-brown-light, .50);

$h1-font-size:                $font-size-base * 3.8;
$h2-font-size:                $font-size-base * 3;
$h3-font-size:                $font-size-base * 1.5;
//$h4-font-size:                $font-size-base * 1.5 !default;
//$h5-font-size:                $font-size-base * 1.25 !default;
//$h6-font-size:                $font-size-base !default;

$card-bg: #f3f1e5;
$card-border-radius: 0.7rem;
$card-deck-margin: 0px;

$breadcrumb-divider: '>';

.card-deck {
  background-color: $bft-lime-green;
  padding: 2rem;
  margin-right: 0px !important;
  margin-left: 0px !important;
  padding-left: $left-padding-alignment - 12px !important;
  padding-right: 20px !important;
  margin-bottom: 2rem !important;
}

// Fixes and issue with vertical spacing of cards for middle width viewports
@media screen and (min-width: map-get($grid-breakpoints, "sm")) and  (max-width: map-get($grid-breakpoints, "lg")){
  .card-deck .card {
    margin-bottom: 20px !important;
  }
}

.navbar {
  background-color: $bft-off-white;
}

.navbar-dark {
  background-color: $bft-taupe-dark;
}

@media screen and (max-width: map-get($grid-breakpoints, "lg")) {
  #primary-navigation-full {
    display: none !important;
  }
}

@media screen and (min-width: map-get($grid-breakpoints, "lg")) {
  #primary-menu-small {
    display: none !important;
  }
}

a {
  text-decoration: none !important;
}

p {
  font-family: $font-family-base;
}

.navbar-dark .nav-link {
  padding-top: 0px;
  padding-bottom: 0px;
  margin: 0px 10px 0px 10px;
  font-weight: bold;
  font-family: $font-family-alternate
}

.navbar-dark .dropdown-item{
  font-family: $font-family-alternate
}

.navbar .nav-link {
  font-family: $font-family-alternate
}

.navbar-brand {
  padding-left: $left-padding-alignment-navbar;
  font-style: normal;
}

// .navbar-expand-lg {
//   height: 80px;
// }

.navbar-toggler {
  margin-left: auto !important;
  margin-right: 10px;
}

@media screen and (min-width: map-get($grid-breakpoints, "lg")) {
  .donate-collapsed {
    display: none !important;
  }
  .donate-expanded {
    display: inherit !important;
  }

  .donate-collapsed-parent {
  }
}
@media screen and (max-width: map-get($grid-breakpoints, "lg")) {
  .donate-collapsed {
    display: inherit !important;
  }
  .donate-expanded {
    display: none !important;
  }

  .donate-collapsed-parent {
    margin-bottom: 8px;
    margin-top: 8px;
  }
}



#primary-navigation-full {

}

#secondary-navigation {
  font-family: "Aller", serif;
}

#hero {
  h1 {
    color: $bft-off-white;
    font-weight: bold;
  }

  .container-fluid {
    min-height: 500px !important;
    text-align: left;
    vertical-align: middle;
  }

  .tagline {
    color: $bft-off-white;
    font-size: 1.66 * $font-size-base;
    margin-top: -8px;
  }

  .col {
    padding-left: 0px;
    padding-top: 20px;
  }

  a {
    color: $bft-off-white;
    text-decoration: none;
  }

  a:hover {
    color: rgba($bft-off-white, .7);
    text-decoration: none;
  }
}

#four-links {


  .row{
    background-color: $bft-off-white;
  }

  h1 {
    color: $bft-brown-light;
    font-weight: bold;
  }

  h2 {
    color: $bft-brown-light;
    font-weight: bold;
    font-size: $font-size-base * 1.5;
    margin-bottom: 0;
  }

  .fa-stack {
    font-size: 1em;
    color: $bft-taupe-dark;
  }

  .fa-stack .fa-inverse {
    color: $bft-lime-green-light;
  }

  .tagline {
    color: $bft-brown-light;
    font-size: 1rem;
  }

}

.four-links-content {
  padding-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;

  h2 {
    margin-top: 0 !important;
  }
}

#three-cards {

  h2 {
    color: $bft-sage;
    font-weight: bold;
    font-size: $font-size-base * 1.5
  }

  .tagline {
    color: $bft-sage-light;
    font-size: 1rem;
    display: -webkit-box;
    height: 4.5rem;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

#four-cards {
  h2 {
    color: $bft-off-white;
    font-weight: bold;
    font-size: $font-size-base * 3.0
  }

  .tagline {
    color: $bft-brown-light;
    font-size: 1rem;
  }
}

#four-cards .card {

  margin-bottom: 1rem;

  h2 {
    color: $bft-brown-light;
    font-weight: bold;
    font-size: $font-size-base * 3.0
  }

  .fa-stack {
    font-size: 1em;
    color: #ccd553;
  }

  .fa-stack .fa-inverse {
    color: #5c5a5b;
  }
}


#four-images {
  padding-top: 1rem;
//  margin-bottom: 1rem;
//  background-color: $bft-off-white;

  .tagline {
    font-weight: bold;
    font-size: $font-size-base * 1.5;
    color: $bft-taupe;
    padding-top: 2rem;
    padding-bottom: 2rem;
    text-align: center;
  }

  .row {
    padding-left: $left-padding-alignment;
    padding-right: $right-padding-alignment;
    padding-bottom: 1rem;
    background-color: $bft-off-white;
  }

  .instagram-link {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  a {
    color: $bft-tan;
    text-decoration: none;
    font-size: 1.25rem;
  }

  a:hover {
    color: rgba($bft-tan, .7);
    text-decoration: none;
  }

  [class*='col'] {
    padding-left:0px;
    padding-right:0px;
  }
}

#article {
  padding: 20px;
  background-color: $bft-off-white;
  font-family: $font-family-base;

  h1, h2, h3 {
    color: $bft-taupe-dark;
  }

  h2, h3 {
    margin-top: 2rem;
    margin-bottom: 0;
  }


  .tagline {
    font-size: 1.66 * $font-size-base;
    color: $bft-tan;
  }

  p {
    font-size: 1.2 * $font-size-base;
    color: black;
  }
}

#article-top-image-row {
  // margin-top: -20px;
  // margin-left: -20px;
  // margin-right: -20px;
  width: 100%;
  padding-top: 45%;
  position: relative;
  overflow: hidden;

}

#article-top-image {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

#article-heading-block {
    margin-bottom: 1rem;
}

#arcticle-tag-list {

}

#tag {
  margin-right: 5px;
  padding: 4px;
  font-size: 0.8 * $font-size-base;
}

#sidebar-list {
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
}

#article-card {
  background-color: $bft-off-white;
  padding-left: $left-padding-alignment;
  padding-right: $right-padding-alignment;
}

#article-navigation {
  background-color: $bft-off-white;
  padding-left: $left-padding-alignment / 2;

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

}

#article-related {
  background-color: $bft-off-white;
  padding-left: $left-padding-alignment / 2;
  ul {
    list-style-type: none;
    margin: 5px 0px 5px 5px;
    padding: 0;
  }

  li {
    margin-bottom: 5px;
  }
}

@media screen and (max-width: map-get($grid-breakpoints, "sm")){
  #search-term {
      width: 80%;
    }
  }


#search-results {
  padding-left: 20px;
  padding-right: 20px;

  .query-matches {
    font-weight: 700;
    color: $bft-brown;
    margin-top: -20px;
    margin-bottom: 10px;
    font-size: 1.1rem;
  }
  .tagline {
    font-weight: 700;
    color: $bft-brown;
    font-size: 1.1rem;
  }
}

#search-body {
  max-height: 3rem;
  text-overflow:ellipsis;
  overflow:hidden;
  // Addition lines for 2 line or multiline ellipsis
  display: -webkit-box !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
  color: $bft-brown;


  strong {
    font-weight: 400;
    color: $bft-brown;
    margin: 0;
    font-style: italic;
    font-size: 1rem;
  }

  p {
    font-weight: 400;
    color: $bft-brown;
    margin: 0;
    font-style: italic;
    font-size: 1rem;
  }

  h2 {
    font-weight: 400;
    color: $bft-brown;
    margin: 0;
    font-style: italic;
    font-size: 1rem;
  }

  h3 {
    font-weight: 400;
    color: $bft-brown;
    margin-top: 0;
    font-style: italic;
    font-size: 1rem;
  }
  hr {
    display: none;
  }
}


#old-website-link {
  border-top-width: 1px;
  border-top-color: $bft-tan;
  border-top-style: solid;
  margin-bottom: 10px;
  padding-top: 1rem;

  border-bottom-width: 1px;
  border-bottom-color: $bft-tan;
  border-bottom-style: solid;
  margin-bottom: 5rem;
  padding-bottom: 1rem;

  margin-left: 20px;
  margin-right: 20px;

  .fa-stack {
    font-size: 1em;
    color: $bft-taupe-dark;
  }

  .fa-stack .fa-inverse {
    color: $bft-lime-green-light;
  }

  .tagline {
    color: $bft-brown-light;
    font-size: 2rem;
  }
}

#article-related-embedded {

  h3 {
    margin-top: 0.5rem;
  }

  border-top-width: 1px;
  border-top-color: $bft-tan;
  border-top-style: solid;
  margin-bottom: 10px;
  padding-top: 1rem;

  border-bottom-width: 1px;
  border-bottom-color: $bft-tan;
  border-bottom-style: solid;
  margin-bottom: 10px;
  padding-bottom: 1rem;

  background-color: $bft-off-white;
  padding-left: $left-padding-alignment / 2;
  ul {
    list-style-type: none;
    margin: 5px 0px 5px 5px;
    padding: 0;
  }

  li {
    margin-bottom: 5px;
  }
}

#quote {
  color: $bft-brown;
  background-color: $bft-off-white;
  padding-left: $left-padding-alignment / 2;


  .tagline {
    font-weight: bold;
    font-size: $font-size-base * 1.5;
    color: $bft-taupe;
    padding-top: 1rem;
    padding-bottom: 2rem;
    text-align: center;
  }

  .call-to-action {
    text-align: center;
    padding-left: 40%;
    text-decoration: none;
    font-size: 1rem;

    a {
      color: $bft-brown;
      font-weight: 400;
      vertical-align: baseline;
    }

    a:hover {
      color: rgba($bft-brown, .7);
      text-decoration: none;
    }

    .fa-stack {
      font-size: 1em;
      color: #ccd553;
      margin-bottom: -0.1rem !important;
    }

    .fa-stack .fa-inverse {
      color: #5c5a5b;
    }
  }
}

.item-left-hand-image {

  width: 100%;

  h3 {
    color: $bft-brown;
    margin-top: 0.5rem !important;
    margin: 10px;
  }

  .card-body {
    margin: 10px;
    padding: 0;
  }

  .card-footer {
    background-color: inherit;
  }


  .call-to-action {
    text-align: center;
    padding-left: 40%;
    text-decoration: none;
    font-size: 1rem;

    a {
      color: $bft-brown;
      font-weight: 400;
      vertical-align: baseline;
    }

    a:hover {
      color: rgba($bft-brown, .7);
      text-decoration: none;
    }

    .fa-stack {
      font-size: 1em;
      color: #ccd553;
      margin-bottom: -0.1rem !important;
    }

    .fa-stack .fa-inverse {
      color: #5c5a5b;
    }
  }
}

#footer {
  background-color: $bft-brown;
  padding-top: 20px;
  color: $bft-off-white;
  font-family: $font-family-alternate;

  .headline {
    font-size: 1.25rem;
  }

  p {
    margin-top: 10px;
    margin-bottom: 10px;
    color: $bft-off-white;
    font-size: 1rem;
  }
  button {
      padding-left: 40px !important;
      padding-right: 40px !important;
  }

  a {
    color: $bft-off-white;
    padding-right: 5px;

  }

  a::after {
    padding-left: 5px;
    content: "|";
  }

  a:hover {
    color: rgba($bft-off-white, .75);
  }

  a:last-child {
    padding-right: 10px;
  }

  a:last-child::after {
      padding-left: 5px;
      content: "";

  }
}

#page-loading {
  background-color: $bft-off-white;
  color: $bft-tan;
  font-family: $font-family-base;

  min-height: 200px;
  padding-top: 25px;
  padding-left: $left-padding-alignment;

  p {
    font-size: 2 * $font-size-base;
    color: $bft-tan;
  }
}

#breadcrumbs {
  background-color: $bft-off-white;
  color: $bft-tan;
  font-family: $font-family-base;
}

.breadcrumb {
  background-color: $bft-off-white !important;
  color: $bft-tan !important;
  font-family: $font-family-base !important;
  padding-left: 0 !important;
  margin-bottom: 0 !important;
  font-style: italic;
}

.breadcrumb-item + .breadcrumb-item::before {
  content: '>';
}

.lp {
  padding-left: $left-padding-alignment
}

.rp {
  padding-right: $left-padding-alignment
}

.lp-s {
  padding-left: $left-padding-alignment - 20px
}

@import "../node_modules/bootstrap/scss/bootstrap";

h2, h3 {
  font-weight: bold
}

.map-container {
  height: 600px;
}

//changed -20pm to 20px - FL
#image-gallery {
  margin-left: 20px;
  margin-right: 20px;

  .tagline {
    font-size: 1.66 * $font-size-base;
    color: $bft-tan;
  }

  p {
    font-size: 1.2 * $font-size-base;
    color: black;
  }
}

#image-and-text {

  .tagline {
    margin-left: 10px;
    margin-bottom: 5px;
  }

  .card-body {
    margin-top: 5px;
  }
}

#sitemap {
  li {
    list-style-type: '-';
    padding-left: 10px;
  }

  .sitemap-url {
    font-size: small;
    display: inline-block;
  }
}

// Support for reduced size stacked icons from fontawesome
// https://fontawesome.com/how-to-use/on-the-web/styling/stacking-icons


i { vertical-align: middle; }
